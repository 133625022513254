import type { LicenseUsage } from '~/types/settings'

export const useLicenses = () => {
  const { data: licenses } = useQueryLicense.all()

  const createIsSeatsAvailableComputed = (key: keyof LicenseUsage) => computed(() => {
    if (!licenses.value)
      return false

    const { allowed, used } = licenses.value[key]

    return allowed === -1 || used < allowed
  })

  const createHasUnlimitedSeatsComputed = (key: keyof LicenseUsage) =>
    computed(() => licenses.value?.[key].allowed === -1)

  return {
    licenses,

    // The following computeds are not grouped into object to make destructuring in components easier.
    // Exporting them grouped would mean that one either has to use ".value" in the templates or
    // destructure them deeply which renders the grouped advantage obsolete.
    isUserSeatAvailable: createIsSeatsAvailableComputed('user'),
    hasUnlimitedUserSeats: createHasUnlimitedSeatsComputed('user'),

    isBuilderSeatAvailable: createIsSeatsAvailableComputed('builder'),
    hasUnlimitedBuilderSeats: createHasUnlimitedSeatsComputed('builder'),

    isWorkspaceSeatAvailable: createIsSeatsAvailableComputed('workspace'),
    hasUnlimitedWorkspaceSeats: createHasUnlimitedSeatsComputed('workspace'),
  }
}
