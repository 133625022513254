<script lang="ts" setup>
import type { PartialPermission } from '~/types'

const props = defineProps<{
  permission: PartialPermission
}>()

const emit = defineEmits<{
  (e: 'update:permission', v: PartialPermission): void
}>()

const permissionLevelHandler = computed({
  get() {
    if (isFullAccessPermission(props.permission))
      return 'manage'

    if (isViewAccessPermission(props.permission))
      return 'view'

    return 'none'
  },
  set(val) {
    if (val === 'manage') {
      emit('update:permission', {
        ...props.permission,
        can_delete: true,
        can_read: true,
        can_see: true,
        can_write: true,
      })
    }
    else if (val === 'view') {
      emit('update:permission', {
        ...props.permission,
        can_delete: false,
        can_read: true,
        can_see: true,
        can_write: false,
      })
    }
    else {
      emit('update:permission', {
        ...props.permission,
        can_delete: false,
        can_read: false,
        can_see: false,
        can_write: false,
      })
    }
  },
})
</script>

<template>
  <DTRadio
    v-model="permissionLevelHandler"
    value="manage"
    :label="$t('settings.invite-modal.permission.manage-title')"
    size="sm"
    :description="$t('settings.invite-modal.permission.manage-description')"
  />
  <DTRadio
    v-model="permissionLevelHandler"
    value="view"
    :label="$t('settings.invite-modal.permission.view-title')"
    size="sm"
    :description="$t('settings.invite-modal.permission.view-description')"
  />
  <DTRadio
    v-model="permissionLevelHandler"
    value="none"
    :label="$t('settings.invite-modal.permission.none-title')"
    size="sm"
    :description="$t('settings.invite-modal.permission.none-description')"
  />
</template>
