<script lang="ts" setup>
const emit = defineEmits<{
  select: [folder: App.Data.Folder.FolderData]
}>()

const { isModalOpen, openModal, closeModal } = useModal()
const { isLoading, startLoading, stopLoading } = useLoadingCount()
const folderId = ref('')
const error = ref(false)

const close = () => {
  folderId.value = ''

  closeModal()
}

const submit = async () => {
  if (!folderId.value || isLoading.value)
    return

  error.value = false
  startLoading()

  try {
    const folder = await useApi().fetchFolder(folderId.value)().then(r => r.data)
    emit('select', folder)
    close()
  }
  catch (e) {
    console.error(e)
    error.value = true
  }
  finally {
    stopLoading()
  }
}

defineExpose({
  open: openModal,
})
</script>

<template>
  <DTModal
    v-model="isModalOpen"
    @close="close()"
  >
    <DTModalHeader :title="$t('settings.select-folder-modal.title')" />
    <DTModalBody class="space-y-6 !p-6">
      <ErrorAlert
        v-if="error"
        :error="$t('unknown-error')"
      />
      <span
        class="inline-block text-sm text-gray-600"
      >{{ $t('settings.select-folder-modal.guide') }}</span>
      <DocumentsFolderSelector
        v-model="folderId"
      />
    </DTModalBody>
    <DTModalFooter>
      <DTButton @click="close()">
        {{ $t('cancel') }}
      </DTButton>
      <DTButton
        variant="blue"
        :disabled="!folderId || isLoading"
        @click="submit"
      >
        {{ $t('add') }}
        <DTSpinner
          v-if="isLoading"
          variant="white"
          :size="4"
          :weight="2"
        />
      </DTButton>
    </DTModalFooter>
  </DTModal>
</template>
