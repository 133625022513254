<template>
  <div
    v-bind="$attrs"
    class="
        flex
        h-[3.75rem]
        select-none
        items-center
        justify-between
        px-6
        py-3
        first:rounded-t-lg
        last:rounded-b-lg
        "
  >
    <slot />
  </div>
</template>
