<script lang="ts" setup>
import LineX from '@docue/docue-ui-v2/icons/LineX.vue'
import LineTrash01 from '@docue/docue-ui-v2/icons/LineTrash01.vue'
import LineSettings02 from '@docue/docue-ui-v2/icons/LineSettings02.vue'
import LineRefreshCw01 from '@docue/docue-ui-v2/icons/LineRefreshCw01.vue'
import LineDotsHorizontal from '@docue/docue-ui-v2/icons/LineDotsHorizontal.vue'
import { ListItemBoundary } from '~/types/ui'

const props = defineProps<{
  permission: App.Data.Workspace.WorkspacePermissionData
}>()

const emit = defineEmits<{
  (e: 'open', user: App.Data.Workspace.WorkspacePermissionData): void
  (e: 'manageAccess'): void
}>()

const boundary = inject(ListItemBoundary)
const { confirmDelete } = useDialog()
const { licenses, isBuilderSeatAvailable } = useLicenses()
const { hasValidSubscription } = useSubscription()

const { t } = useI18n()

const userPermits = computed(() => {
  const permits = props.permission.permits
  if (!Array.isArray(permits))
    return []

  return permits.map(p => p.permit).filter(Boolean)
})

const user = computed(() => props.permission.user)
const invite = computed(() => props.permission.invite)
const isAdmin = computed(() => userPermits.value.includes('admin'))
const permitToggleAvailable = computed(() =>
  hasValidSubscription.value && licenses.value,
)

const hasBuilderPermit = ref<boolean>()

const isPermitEditAllowed = computed(
  () => userPermits.value.includes('builder')
  || !!hasBuilderPermit.value
  || isBuilderSeatAvailable.value,
)

const permitController = computed({
  get() {
    return hasBuilderPermit.value ?? userPermits.value.includes('builder')
  },
  set(checked: boolean) {
    hasBuilderPermit.value = checked
    maybeUpdateBuilderPermit()
  },
})

const { mutateAsync: dropWorkspacePermission } = useMutationWorkspacePermission.drop()
const { mutateAsync: updateWorkspacePermission } = useMutationWorkspacePermission.update()

const { activeWorkspaceId } = useAuth()

// eslint-disable-next-line func-style
async function maybeUpdateBuilderPermit() {
  // use function declaration for hoisting
  if (
    !hasBuilderPermit.value && licenses.value && licenses.value.builder.allowed !== -1
    && licenses.value.builder.used > licenses.value.builder.allowed
    && !(await confirmDelete({ message: t('settings.users.licenses.confirm-removal') }))
  ) {
    hasBuilderPermit.value = true
    return
  }

  if (!user.value || !activeWorkspaceId.value)
    return

  boundary?.startLoading()

  let permits = [...userPermits.value]
  if (permits.includes('builder') && !permitController.value)
    permits = permits.filter(p => p !== 'builder')
  else if (!permits.includes('builder') && permitController.value)
    permits.push('builder')

  try {
    await updateWorkspacePermission({
      id: props.permission.id,
      workspaceId: activeWorkspaceId.value,
      payload: { permits },
    })
    boundary?.showSuccess()
  }
  catch (e) {
    console.error(e)
    boundary?.setError(t('settings.users.licenses.update-failure'))

    // optimistically try to return previous value to UI.
    if (userPermits.value.includes('builder'))
      hasBuilderPermit.value = true
    else
      hasBuilderPermit.value = false
  }
  finally {
    boundary?.stopLoading()
  }
}

const resendInvitation = async () => {
  if (!invite.value)
    return

  boundary?.startLoading()
  try {
    await useApi().resendWorkspaceInvitation(invite.value.id)
    boundary?.showSuccess()
  }
  catch (e) {
    console.error(e)
    boundary?.setError(t('settings.users.list.resend-failure'))
  }
  finally {
    boundary?.stopLoading()
  }
}

const revokeInvitation = async () => {
  if (!invite.value || !activeWorkspaceId.value)
    return

  if (!(await confirmDelete({
    message: t('settings.users.list.confirm-revoke', {
      email: invite.value.email,
    }),
  }))) {
    return
  }

  boundary?.startLoading()
  try {
    await dropWorkspacePermission({
      id: props.permission.id,
      workspaceId: activeWorkspaceId.value,
    })
  }
  catch (e) {
    console.error(e)
    boundary?.setError(t('settings.users.list.revoke-failure'))
  }
  finally {
    boundary?.stopLoading()
  }
}

const deleteUser = async () => {
  if (!user.value || !activeWorkspaceId.value || !(await confirmDelete({
    message: t('settings.users.list.confirm-delete', {
      user: user.value.display_name || user.value.email,
    }),
  }))) {
    return
  }

  boundary?.startLoading()
  try {
    await dropWorkspacePermission({
      id: props.permission.id,
      workspaceId: activeWorkspaceId.value,
    })
  }
  catch (e) {
    console.error(e)
    boundary?.setError(t('settings.users.list.user-delete-failure'))
  }
  finally {
    boundary?.stopLoading()
  }
}
</script>

<template>
  <div
    class="
        flex
        items-center
        justify-between
        gap-2
        px-4.5
        py-3
        md:grid
        md:grid-cols-[1.5fr,1fr,1fr]
        "
  >
    <div class="relative flex items-center gap-2">
      <div
        v-if="user"
        role="button"
        tabindex="0"
        class="absolute inset-0 md:hidden"
        @click="emit('open', permission)"
      />
      <DTAvatar
        :name="user?.display_name || invite?.email"
        :variant="user ? 'colored' : 'default'"
        size="md"
      />
      <div class="flex flex-col">
        <span
          v-if="user"
          v-dt-tooltip.dark="user.display_name"
          class="line-clamp-1 w-max break-all text-sm"
        >{{ user.display_name }}</span>
        <span
          v-else
          class="text-sm text-yellow-600"
        >
          {{ $t('settings.users.list.invited-text') }}
        </span>
        <span class="line-clamp-1 break-all text-sm text-gray-500">
          {{ user?.email || invite?.email }}
        </span>
      </div>
    </div>
    <div
      v-if="permitToggleAvailable"
      class="hidden md:block"
    >
      <DTSwitch
        v-model="permitController"
        :disabled="!!invite || !isPermitEditAllowed"
      />
    </div>
    <div
      class="flex items-center justify-between gap-2"
      :class="{ 'col-start-2 col-end-[-1]': !permitToggleAvailable }"
    >
      <div
        v-bind="user
          ? { role: 'button', tabindex: '0', onClick: () => emit('manageAccess') }
          : undefined"
        class="hidden flex-col md:flex"
      >
        <div class="flex items-center gap-2">
          <span
            class="text-sm"
            :class="{ 'text-yellow-600': !!invite }"
          >
            {{
              isAdmin
                ? $t('settings.users.list.access-level.admin')
                : $t('settings.users.list.access-level.member')
            }}
          </span>
        </div>
        <span
          v-if="!isAdmin && permission.folder_permissions?.length"
          class="text-xs text-gray-500"
        >
          {{ $t('settings.users.list.defined-access', permission.folder_permissions.length) }}
        </span>
      </div>
      <DTDropdown>
        <DTDropdownButton
          is="DTIconButton"
          variant="plain"
        >
          <LineDotsHorizontal class="size-4 text-gray-500" />
        </DTDropdownButton>
        <DTDropdownMenu placement="bottom-start">
          <template v-if="user">
            <DTDropdownMenuItem
              class="text-sm"
              @click="emit('manageAccess')"
            >
              <LineSettings02 class="w-4 shrink-0" />
              {{ $t('settings.users.list.edit-access') }}
            </DTDropdownMenuItem>
            <DTDropdownMenuItem
              class="text-sm text-red-500 hover:text-red-600"
              @click="deleteUser"
            >
              <LineTrash01 class="w-4 shrink-0" />
              {{ $t('settings.users.list.actions.delete-user') }}
            </DTDropdownMenuItem>
          </template>
          <template v-else>
            <DTDropdownMenuItem
              class="text-sm"
              @click="resendInvitation"
            >
              <LineRefreshCw01 class="w-4 shrink-0" />
              {{ $t('settings.users.list.actions.resend-invite') }}
            </DTDropdownMenuItem>
            <DTDropdownMenuItem
              class="text-sm text-red-500 hover:text-red-600"
              @click="revokeInvitation"
            >
              <LineX class="w-4 shrink-0" />
              {{ $t('settings.users.list.actions.revoke-invite') }}
            </DTDropdownMenuItem>
          </template>
        </DTDropdownMenu>
      </DTDropdown>
    </div>
  </div>
</template>
