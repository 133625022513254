import { useQueryClientUtils } from '../useQueryClientUtils'
import { responseDataTypeName, useFetchWorkspacePermission } from './useFetchWorkspacePermission'
import { queryKeys as licenseQueryKeys } from './../license/useQueryLicense'
import { queryKeys as workspacePermissionQueryKeys } from './useQueryWorkspacePermission'

const create = () => {
  const { create } = useFetchWorkspacePermission()
  const { cancelQueries, invalidateQueries, invalidateRelationalCacheDataByType } = useQueryClientUtils()

  return useMutation(
    {
      mutationFn: create, // workspace id is not supplied here, because it is needed in onSettled for example
      onMutate: (variables) => {
        cancelQueries([
          workspacePermissionQueryKeys.all(variables).queryKey,
          workspacePermissionQueryKeys.allUnpaginated(variables).queryKey,
          licenseQueryKeys.all(variables).queryKey,
        ])
      },
      onSettled: (response, _, variables) => {
        if (response?.data._type)
          invalidateRelationalCacheDataByType(response?.data._type)

        invalidateQueries([
          workspacePermissionQueryKeys.all(variables).queryKey,
          workspacePermissionQueryKeys.allUnpaginated(variables).queryKey,
          licenseQueryKeys.all(variables).queryKey,
        ])
      },
    },
  )
}

const update = () => {
  const { update } = useFetchWorkspacePermission()
  const { cancelQueries, invalidateQueries, invalidateRelationalCacheDataByType } = useQueryClientUtils()

  return useMutation({
    mutationFn: update,
    onMutate: (variables) => {
      cancelQueries([
        workspacePermissionQueryKeys.all(variables).queryKey,
        workspacePermissionQueryKeys.allUnpaginated(variables).queryKey,
        workspacePermissionQueryKeys.byId(variables).queryKey,
        licenseQueryKeys.all(variables).queryKey,
      ])
    },
    onSettled: (response, _, variables) => {
      if (response?.data._type)
        invalidateRelationalCacheDataByType(response?.data._type)

      invalidateQueries([
        workspacePermissionQueryKeys.all(variables).queryKey,
        workspacePermissionQueryKeys.allUnpaginated(variables).queryKey,
        workspacePermissionQueryKeys.byId(variables).queryKey,
        licenseQueryKeys.all(variables).queryKey,
      ])
    },
  })
}

const drop = () => {
  const { drop } = useFetchWorkspacePermission()
  const { removeQueries, cancelQueries, invalidateQueries, invalidateRelationalCacheDataByType } = useQueryClientUtils()

  return useMutation({
    mutationFn: drop,
    onMutate: (variables) => {
      cancelQueries([
        workspacePermissionQueryKeys.all(variables).queryKey,
        workspacePermissionQueryKeys.allUnpaginated(variables).queryKey,
        workspacePermissionQueryKeys.byId(variables).queryKey,
        licenseQueryKeys.all(variables).queryKey,
      ])
    },
    onSuccess: (_, variables) => {
      removeQueries([
        workspacePermissionQueryKeys.byId(variables).queryKey,
      ])
    },
    onSettled: (_, _1, variables) => {
      invalidateRelationalCacheDataByType(responseDataTypeName)

      invalidateQueries([
        workspacePermissionQueryKeys.all(variables).queryKey,
        workspacePermissionQueryKeys.allUnpaginated(variables).queryKey,
        licenseQueryKeys.all(variables).queryKey,
      ])
    },
  })
}

export const useMutationWorkspacePermission = {
  create,
  update,
  drop,
}
