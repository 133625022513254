<script lang="ts" setup>
import LineX from '@docue/docue-ui-v2/icons/LineX.vue'
import LineAlertCircle from '@docue/docue-ui-v2/icons/LineAlertCircle.vue'
import LineCheckCircle from '@docue/docue-ui-v2/icons/LineCheckCircle.vue'
import { useTimeoutFn } from '@vueuse/core'
import { ListItemBoundary } from '~/types/ui'

const { isLoading, stopLoading, startLoading } = useLoadingCount()

const error = ref('')

const clearError = () => {
  error.value = ''
  stopLoading()
}

const setError = (errorMessage: string) => {
  error.value = errorMessage
}

const shouldShowSuccess = ref(false)
const { start: startSuccessTimeout } = useTimeoutFn((cb?: () => void) => {
  shouldShowSuccess.value = false
  cb?.()
}, 750, { immediate: false })

provide(ListItemBoundary, {
  startLoading,
  stopLoading,
  setError,
  showSuccess: (cb) => {
    stopLoading()
    shouldShowSuccess.value = true
    startSuccessTimeout(cb)
  },
})
</script>

<template>
  <div class="relative">
    <slot />

    <div
      v-if="error || isLoading || shouldShowSuccess"
      class="absolute inset-0 flex items-center justify-center bg-white/80"
    >
      <DTAlert
        v-if="error"
        class="relative"
        variant="red"
      >
        <LineAlertCircle class="size-4 shrink-0" />
        <div class="flex flex-col">
          <p>{{ error }}</p>
        </div>
        <DTIconButton
          size="sm"
          variant="plain"
          class="ml-auto mr-0 shrink-0 text-red-600 hover:text-red-700"
          @click="clearError()"
        >
          <LineX class="size-4" />
        </DTIconButton>
      </DTAlert>

      <div
        v-if="shouldShowSuccess || isLoading"
        class="flex h-14 w-32 items-center justify-center rounded-xl bg-white"
      >
        <DTLoaderDots v-if="isLoading" />
        <LineCheckCircle
          v-else-if="shouldShowSuccess"
          class="size-6 text-green-500"
        />
      </div>
    </div>
  </div>
</template>
