<script lang="ts" setup>
import LineAlertCircle from '@docue/docue-ui-v2/icons/LineAlertCircle.vue'
import LineTrash01 from '@docue/docue-ui-v2/icons/LineTrash01.vue'

const emit = defineEmits<{
  (e: 'update'): void
  (e: 'remove', user: App.Data.Workspace.WorkspacePermissionData): void
}>()

const { licenses, isBuilderSeatAvailable, hasUnlimitedBuilderSeats } = useLicenses()

const { isModalOpen, closeModal, openModal } = useModal()
const { confirmDelete } = useDialog()
const { isLoading, startLoading, stopLoading } = useLoadingCount()
const { isLoadingDelete, startLoadingDelete, stopLoadingDelete } = useLoadingCount('delete')
const { hasValidSubscription } = useSubscription()
const { t } = useI18n()
const permission = ref<App.Data.Workspace.WorkspacePermissionData>()
const hasBuilderPermit = ref<boolean>()
const error = ref('')

const user = computed(() => permission.value?.user)

const permits = computed(() => {
  const permits = permission.value?.permits
  if (!Array.isArray(permits))
    return []

  return permits.map(p => p.permit).filter(Boolean)
})

const usedLicenseCount = computed(() => {
  if (!permits.value.includes('builder') && hasBuilderPermit.value)
    return (licenses.value?.builder.used ?? 0) + 1

  if (permits.value.includes('builder') && hasBuilderPermit.value === false)
    return Math.max(0, (licenses.value?.builder.used ?? 0) - 1)

  return licenses.value?.builder.used
})

const permitController = computed({
  get() {
    return hasBuilderPermit.value ?? permits.value.includes('builder')
  },
  set(checked: boolean) {
    hasBuilderPermit.value = checked
  },
})

const isPermitEditAllowed = computed(
  () => permits.value.includes('builder')
  || !!hasBuilderPermit.value
  || isBuilderSeatAvailable.value,
)

const close = () => {
  permission.value = undefined
  hasBuilderPermit.value = undefined
  error.value = ''
  closeModal()
}

watch(permission, (permission) => {
  if (!permission)
    close()
})

const maybeSave = async () => {
  if (
    !hasBuilderPermit.value && licenses.value && hasUnlimitedBuilderSeats.value
    && licenses.value.builder.used > licenses.value.builder.allowed
    && !(await confirmDelete({ message: t('settings.users.licenses.confirm-removal') }))
  ) {
    hasBuilderPermit.value = true
    return
  }

  startLoading()
  try {
    await useApi().updateWorkspaceUser({
      id: permission.value!.id,
      attributes: {
        permits: permitController.value ? ['builder'] : [],
      },
    })
    emit('update')
    close()
  }
  catch (e) {
    console.error(e)
    error.value = t('settings.users.licenses.update-failure')

    // optimistically try to return previous value to UI.
    if (permits.value.includes('builder'))
      hasBuilderPermit.value = true
    else
      hasBuilderPermit.value = false
  }
  finally {
    stopLoading()
  }
}

const deleteUser = async () => {
  if (!permission.value || !(await confirmDelete({
    message: t('settings.users.list.confirm-delete', {
      user: user.value?.display_name || user.value?.email,
    }),
  }))) {
    return
  }

  startLoadingDelete()
  try {
    await useApi().deleteWorkspaceUser(permission.value?.id)
    emit('remove', permission.value)
    close()
  }
  catch (e) {
    console.error(e)
    error.value = t('settings.users.list.user-delete-failure')
  }
  finally {
    stopLoadingDelete()
  }
}

const open = (data: App.Data.Workspace.WorkspacePermissionData) => {
  permission.value = data
  openModal()
}

defineExpose({
  open,
})
</script>

<template>
  <DTModal
    :model-value="isModalOpen"
    @update:model-value="close"
  >
    <DTModalHeader :title="$t('edit')" />
    <DTModalBody>
      <DTAlert
        v-if="error"
        variant="red"
        class="mb-6"
      >
        <LineAlertCircle class="size-4 shrink-0" />
        {{ error }}
      </DTAlert>
      <div
        v-if="user"
        class="flex flex-col gap-6"
      >
        <div class="flex items-center gap-2">
          <DTAvatar
            :name="user.display_name"
            variant="colored"
          />
          <div class="flex flex-col">
            <span class="line-clamp-1 w-max break-all text-sm">
              {{ user.display_name }}
            </span>
            <span class="line-clamp-1 break-all text-sm text-gray-500">
              {{ user.email }}
            </span>
          </div>
        </div>
        <DTCheck
          v-if="hasValidSubscription && licenses && hasUnlimitedBuilderSeats"
          v-model="permitController"
          :disabled="!isPermitEditAllowed"
          class="select-none"
          :label="$t('settings.edit-modal.license-count', {
            used: usedLicenseCount,
            limit: licenses?.builder.allowed,
          })"
          :description="$t('settings.edit-modal.license-info')"
        />
      </div>
    </DTModalBody>
    <DTModalFooter class="items-stretch !justify-between">
      <DTButton
        variant="default-red"
        :disabled="isLoadingDelete"
        @click="deleteUser"
      >
        <LineTrash01 class="size-4" />
      </DTButton>
      <div class="flex items-center gap-2">
        <DTButton @click="close">
          {{ $t('close') }}
        </DTButton>
        <DTButton
          variant="blue"
          :disabled="isLoading || isLoadingDelete"
          @click="maybeSave"
        >
          {{ $t('save') }}
          <DTSpinner v-if="isLoading" />
        </DTButton>
      </div>
    </DTModalFooter>
  </DTModal>
</template>
