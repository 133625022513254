<script lang="ts" setup>
import LineX from '@docue/docue-ui-v2/icons/LineX.vue'
import LineChevronDown from '@docue/docue-ui-v2/icons/LineChevronDown.vue'
import type { Folder, PartialPermission } from '~/types'
import workspaceFolder from '~/assets/images/workspace-folder.svg?url'
import documentsFolderBig from '~/assets/images/documents-folder-big.svg?url'

defineProps<{
  permission: PartialPermission
}>()

defineEmits<{
  (e: 'remove', permissionId: PartialPermission): void
  (e: 'update:permission', permissionData: PartialPermission): void
}>()

const { t } = useI18n()

const isRootFolder = (folder: Folder | App.Data.Folder.FolderData) =>
  folder.id === rootFolderId.value

const permissionMessages = computed(() => ({
  manage: t('settings.invite-modal.permission.manage-title'),
  view: t('settings.invite-modal.permission.view-title'),
  none: t('settings.invite-modal.permission.none-title'),
}))

const getPermissionLevelMessage = (permission: PartialPermission) => {
  if (isFullAccessPermission(permission))
    return permissionMessages.value.manage

  if (isViewAccessPermission(permission))
    return permissionMessages.value.view

  return permissionMessages.value.none
}
</script>

<template>
  <ListItem class="!px-3 sm:!px-6">
    <template v-if="'folder' in permission && permission.folder">
      <div class="flex grow items-center gap-3">
        <img
          :src="isRootFolder(permission.folder) ? workspaceFolder : documentsFolderBig"
          class="hidden sm:inline"
          width="32"
          height="32"
        >
        <div class="flex min-w-0 grow items-center gap-3">
          <div class="flex min-w-0 grow flex-col overflow-hidden">
            <span class="line-clamp-1 break-all text-gray-600">
              {{ permission.folder.name }}
            </span>
            <div class="flex items-center gap-1 text-xs text-gray-500">
              <template v-if="isRootFolder(permission.folder)">
                {{ $t('workspace') }}
              </template>
              <DocumentsBreadcrumbs :breadcrumbs="permission.folder.breadcrumb" />
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center gap-2 empty:hidden">
        <DTPopover
          :key="`popover-${permission.folder.id}`"
          hide-arrow
          placement="bottom-end"
        >
          <template #button>
            <DTPopoverButton class="w-max">
              <DTButton
                as="div"
                class="!justify-end"
                size="xs"
                variant="plain"
              >
                {{ getPermissionLevelMessage(permission) }}
                <LineChevronDown class="size-4 shrink-0" />
              </DTButton>
            </DTPopoverButton>
          </template>
          <div class="w-[360px] px-4 pb-6 pt-3">
            <div class="flex items-center gap-2">
              <span class="text-xs font-semibold">
                {{ $t('settings.invite-modal.permission.title') }}
              </span>
            </div>
            <div class="mt-6 flex flex-col gap-2">
              <SettingsFolderPermissionLevel
                :permission="permission"
                @update:permission="(updated) => $emit('update:permission', updated)"
              />
            </div>
          </div>
        </DTPopover>
        <DTIconButton
          variant="plain"
          size="sm"
          @click="$emit('remove', permission)"
        >
          <LineX class="size-4" />
        </DTIconButton>
      </div>
    </template>
  </ListItem>
</template>
