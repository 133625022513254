<script lang="ts" setup>
import LinePlus from '@docue/docue-ui-v2/icons/LinePlus.vue'
import LineAlertCircle from '@docue/docue-ui-v2/icons/LineAlertCircle.vue'
import type SelectFolderModal from './SelectFolderModal/SelectFolderModal.vue'
import type { Folder, PartialPermission } from '~/types'
import workspaceFolder from '~/assets/images/workspace-folder.svg?url'

const emit = defineEmits<{
  (e: 'done'): void
}>()

const { t } = useI18n()
const { language, languages } = useLanguage()
const { isModalOpen, closeModal, openModal } = useModal()
const { isLoading, startLoading, stopLoading } = useLoadingCount()
const { displayLanguageName } = useIntl()
const { hasFeature } = useFeatureAccess()
const { activeWorkspaceId } = useAuth()
const { hasValidSubscription } = useSubscription()

const { data: licenses, isPending: licensesLoading } = useQueryLicense.all()
const { hasUnlimitedBuilderSeats } = useLicenses()

const errorOccurred = ref(false)
const email = ref('')
const role = ref<'member' | 'admin'>('member')
const inviteLanguage = ref<App.Internals.Enums.Language>(unref(language))
const folderPermissions = ref<Array<PartialPermission>>([])
const selectFolderModal = ref<InstanceType<typeof SelectFolderModal>>()
const useBuilderLicense = ref(false)

const usedLicenseCount = computed(() => {
  if (useBuilderLicense.value)
    return (licenses.value?.builder.used ?? 0) + 1

  return licenses.value?.builder.used
})

const isCustomRolesAvailable = computed(() =>
  hasValidSubscription.value && hasFeature('archive-custom-roles'))

watch(isCustomRolesAvailable, (isCustomRolesAvailable) => {
  // Invited users should default to admin if custom roles not available
  if (!isCustomRolesAvailable)
    role.value = 'admin'
}, { immediate: true })

const licenseUsageEnabled = computed(() => !licensesLoading.value
  && (hasUnlimitedBuilderSeats.value || useBuilderLicense.value
  || !!(licenses.value && licenses.value?.builder.used < licenses.value?.builder.allowed)))

const languageOptions = computed(
  () => languages.map(key => ({ value: key, text: displayLanguageName(key) || key })),
)

const add = (folder: Folder) => {
  if (folderPermissions.value.find(perm => perm.folder?.id === folder.id))
    return

  folderPermissions.value.push({
    id: crypto.randomUUID(),
    folder,
    can_delete: true,
    can_read: true,
    can_see: true,
    can_write: true,
  })
}

const update = (permission: PartialPermission) => {
  folderPermissions.value = folderPermissions.value.map(
    ({ id, ...rest }) => id === permission.id ? { ...rest, ...permission } : { id, ...rest },
  )
}

const remove = (permission: PartialPermission) => {
  folderPermissions.value = folderPermissions.value.filter(({ id }) => id !== permission.id)
}

const hasFolderAccess = computed(() => !folderPermissions.value.every(isPermissionWithoutAccess))

const submitIsEnabled = computed(() => !!(email.value && !licensesLoading.value && (
  (role.value === 'member' && folderPermissions.value.length && hasFolderAccess.value)
  || role.value === 'admin')
))

const close = () => {
  folderPermissions.value = []
  email.value = ''
  useBuilderLicense.value = false
  inviteLanguage.value = unref(language)
  role.value = 'member'

  closeModal()
}

const { mutateAsync: createWorkspacePermission } = useMutationWorkspacePermission.create()

const draftingLicenseCountLabel = computed(() => [
  t('settings.invite-modal.license-count'),
  hasUnlimitedBuilderSeats.value ? undefined : `${usedLicenseCount.value}/${licenses.value?.builder.allowed}`,
].filter(Boolean).join(' '))

const submit = async () => {
  if (!submitIsEnabled.value || isLoading.value || !activeWorkspaceId.value)
    return

  errorOccurred.value = false
  const permits = [
    useBuilderLicense.value && 'builder',
    role.value === 'admin' && role.value,
  ].filter(Boolean) as Array<'builder' | 'admin'>

  // Explicitly add full access to root folder for admins overwriting other permissions
  const folder_permissions = permits.includes('admin')
    ? [{
        folder_id: rootFolderId.value!,
        can_see: true,
        can_delete: true,
        can_read: true,
        can_write: true,
      }]
    : folderPermissions.value.map(

      ({ id, folder, ...rest }) => ({ folder_id: folder!.id, ...rest }),
    )

  startLoading()
  try {
    await createWorkspacePermission({
      payload: {
        email: email.value,
        language: inviteLanguage.value,
        permits,
        folder_permissions,
      },
      workspaceId: activeWorkspaceId.value,
    })

    emit('done')
    close()
  }
  catch (e) {
    console.error(e)
    errorOccurred.value = true
  }
  finally {
    stopLoading()
  }
}

const open = () => {
  openModal()
}

defineExpose({
  open,
})
</script>

<template>
  <DTModal
    :model-value="isModalOpen"
    :close-on-click-outside="false"
    @close="close"
  >
    <SettingsSelectFolderModal
      ref="selectFolderModal"
      @select="add"
    />
    <DTModalHeader :title="$t('settings.invite-modal.title')" />
    <DTModalBody class="md:py-6">
      <DTAlert
        v-if="errorOccurred"
        variant="red"
        class="mb-6"
      >
        <LineAlertCircle class="size-4" />
        <span>{{ $t('unknown-error') }}</span>
      </DTAlert>
      <form
        id="invite-modal"
        class="flex flex-col gap-6"
        @submit.prevent="submit"
      >
        <DTField :label="$t('email')">
          <DTFieldInput
            v-model="email"
            type="email"
            placeholder="email@example.com"
          />
        </DTField>

        <div class="flex flex-col gap-3">
          <span class="text-sm font-medium">
            {{ $t('settings.invite-modal.role.title') }}
          </span>
          <DTRadio
            v-model="role"
            value="admin"
            :label="$t('settings.invite-modal.role.admin-title')"
            :description="$t('settings.invite-modal.role.admin-description')"
            :disabled="!isCustomRolesAvailable"
          />
          <DTRadio
            v-model="role"
            value="member"
            :label="$t('settings.invite-modal.role.member-title')"
            :description="$t('settings.invite-modal.role.member-description')"
            :disabled="!isCustomRolesAvailable"
          />
        </div>

        <div class="flex flex-col gap-3">
          <List v-if="folderPermissions?.length && role === 'member'">
            <SettingsManageAccessPermissionRow
              v-for="permission in folderPermissions"
              :key="permission.id"
              :permission="permission"
              @remove="remove"
              @update:permission="update"
            />
          </List>

          <div
            v-else-if="role !== 'admin'"
            class="
                flex
                h-[3.75rem]
                items-center
                justify-between
                rounded-lg
                border
                border-gray-200
                bg-gray-50
                px-6
                py-3
                "
          >
            <div class="flex grow flex-col items-center !justify-center">
              <span class="text-gray-600">{{ $t('settings.invite-modal.missing-title') }}</span>
              <span class="text-xs font-medium text-gray-500">
                {{ $t('settings.invite-modal.missing-description') }}
              </span>
            </div>
          </div>

          <div
            v-else
            class="
                flex
                h-[3.75rem]
                items-center
                justify-between
                rounded-lg
                border
                border-gray-200
                p-3
                sm:px-6
                "
          >
            <div class="flex items-center gap-3">
              <img
                :src="workspaceFolder"
                width="32"
                height="32"
                class="hidden sm:inline"
              >
              <div class="flex min-w-0 grow flex-col">
                <span class="line-clamp-1 text-gray-600">Docue</span>
                <span class="text-xs text-gray-500">{{ $t('workspace') }}</span>
              </div>
            </div>
            <span class="text-xs font-medium text-gray-800">
              {{ $t('settings.manage-access.full-access') }}
            </span>
          </div>

          <div
            v-if="role === 'member'"
            class="flex flex-col justify-between gap-2 sm:flex-row sm:items-center"
          >
            <DTButton
              size="sm"
              class="w-max"
              @click="selectFolderModal!.open()"
            >
              <LinePlus class="size-4 shrink-0" />
              {{ $t('settings.invite-modal.add-folder') }}
            </DTButton>
            <span
              v-if="!folderPermissions.length || !hasFolderAccess"
              class="text-xs text-gray-500"
            >
              {{ $t('settings.manage-access.missing-access-description') }}
            </span>
          </div>
        </div>

        <div class="flex flex-col">
          <DTCheck
            v-model="useBuilderLicense"
            :label="draftingLicenseCountLabel"
            :description="$t('settings.invite-modal.license-info')"
            :disabled="!licenseUsageEnabled"
          />
        </div>

        <DTField :label="$t('settings.invite-modal.invite-language')">
          <DTFieldSelect
            v-model="inviteLanguage"
            :options="languageOptions"
          />
        </DTField>
      </form>
    </DTModalBody>
    <DTModalFooter>
      <DTButton @click="close">
        {{ $t('cancel') }}
      </DTButton>
      <DTButton
        form="invite-modal"
        :disabled="!submitIsEnabled || isLoading"
        variant="blue"
        type="submit"
      >
        <DTLoaderDots
          v-if="isLoading"
          variant="white"
        />
        {{ $t('settings.invite-modal.send-invite') }}
      </DTButton>
    </DTModalFooter>
  </DTModal>
</template>
