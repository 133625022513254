<script setup lang="ts">
import LineX from '@docue/docue-ui-v2/icons/LineX.vue'
import SolidStar06 from '@docue/docue-ui-v2/icons/SolidStar06.vue'
import LineSearchMd from '@docue/docue-ui-v2/icons/LineSearchMd.vue'
import LineInfoCircle from '@docue/docue-ui-v2/icons/LineInfoCircle.vue'
import { useRouteQuery } from '@vueuse/router'
import LineAlertCircle from '@docue/docue-ui-v2/icons/LineAlertCircle.vue'
import UserEditModal from '~/components/Settings/UserEditModal.vue'
import UserInviteModal from '~/components/Settings/UserInviteModal.vue'
import type { ParametersGetAll } from '~/composables/api/workspace-permission/useFetchWorkspacePermission'
import type { SettingsManageAccessModalProps } from '~/components/Settings/ManageAccess/ManageAccessModal.vue'

definePageMeta({
  layout: 'settings',
  middleware: () => {
    const { isCurrentUserAdmin } = useAuth()
    if (!isCurrentUserAdmin.value)
      return navigateTo('/settings', { external: true })
  },
})

const { t } = useI18n()

const { hasValidSubscription } = useSubscription()

const { licenses, isUserSeatAvailable, hasUnlimitedBuilderSeats } = useLicenses()

const page = useRouteQuery('page', 1, { transform: Number })
const searchQuery = useRouteQuery('query', '', { mode: 'replace' })

const searchQueryDebounced = refDebounced(searchQuery, 200)

const params = computed<ParametersGetAll>(() => ({
  ...(searchQueryDebounced.value ? { 'filter[search]': searchQueryDebounced.value } : {}),
  page: page.value,
  per_page: 8,
  sort: ['-type', 'name'],
}))

const query = useQueryWorkspacePermission.all(
  {
    params,
    fieldsToInclude: [
      'folder_permissions.folder.breadcrumb',
      'user',
      'invite',
      'permits',
    ],
    useQueryOptions: {
      placeholderData: (previousData: any) => previousData,
    },
  },
)

const { data, isFetched } = query

const permissions = computed(() => data.value?.data || [])
const pageCount = computed(() => data.value?.meta.last_page || 1)
const isPaginationVisible = computed(() => pageCount.value > 1)

watchEffect(() => {
  if (isFetched.value && page.value > pageCount.value)
    page.value = 1
})

const inviteModal = ref<InstanceType<typeof UserInviteModal>>()
const editModal = ref<InstanceType<typeof UserEditModal>>()

const permissionToManageAccessFor = ref<SettingsManageAccessModalProps['workspacePermission'] | null>(null)
</script>

<template>
  <Head>
    <Title>{{ t('settings.users-title') }}</Title>
  </Head>
  <LazySettingsManageAccessModal
    v-if="permissionToManageAccessFor"
    :model-value="!!permissionToManageAccessFor"
    :workspace-permission="permissionToManageAccessFor"
    @update:model-value="permissionToManageAccessFor = null"
  />
  <UserInviteModal ref="inviteModal" />
  <UserEditModal ref="editModal" />
  <div class="mb-6 md:mb-10">
    <h1 class="hidden text-3xl font-semibold md:block">
      {{ t('settings.users.title') }}
    </h1>
    <div
      class="
          mt-2
          flex
          flex-col
          items-start
          justify-between
          gap-4
          md:mt-6
          md:flex-row
          md:items-center
          "
    >
      <span class="text-sm text-gray-600 md:text-base">
        {{ t('settings.users.about-text') }}
      </span>
      <FeatureBlocker
        #default="{ enabled, action }"
        :enabled="isUserSeatAvailable"
      >
        <DTButton
          variant="blue"
          size="sm"
          @click="action(() => inviteModal!.open())"
        >
          {{ t('settings.users.action-button') }}
          <SolidStar06
            v-if="!enabled"
            class="size-4 text-yellow-400"
          />
        </DTButton>
      </FeatureBlocker>
    </div>
  </div>

  <div
    class="overflow-hidden rounded-xl border border-gray-200 bg-white pt-3 md:pt-4.5"
  >
    <div class="border-b border-b-gray-200 px-3 pb-3 md:border-b-0 md:px-4.5">
      <DTFieldInputWrapper class="!bg-gray-50">
        <DTFieldInputInlineAddon>
          <LineSearchMd
            class="size-5 text-gray-400 group-focus-within:text-gray-500"
          />
        </DTFieldInputInlineAddon>
        <DTFieldInput
          v-model.trim="searchQuery"
          class="bg-transparent"
          :placeholder="t('settings.users.search-guide')"
        />
        <DTFieldInputInlineAddon v-if="searchQuery">
          <DTIconButton
            size="sm"
            variant="plain"
            @click="searchQuery = ''"
          >
            <LineX
              class="size-5 text-gray-400 group-focus-within:text-gray-500"
            />
          </DTIconButton>
        </DTFieldInputInlineAddon>
      </DTFieldInputWrapper>
    </div>
    <div
      class="hidden grid-cols-[1.5fr,1fr,1fr] gap-2 border-b px-4.5 py-3 md:grid"
    >
      <span class="text-sm text-gray-600">
        {{ t('settings.users.list.name-title') }}
      </span>
      <span
        v-if="hasValidSubscription && licenses"
        class="flex items-center gap-1 text-sm text-gray-600"
      >
        {{ t('settings.users.list.license-title') }}

        <span v-if="!hasUnlimitedBuilderSeats">
          ({{ licenses.builder.used }}/{{ licenses.builder.allowed }})
        </span>

        <span v-dt-tooltip.dark="t('settings.users.list.license-info')">
          <LineInfoCircle class="inline size-4 text-gray-500" />
        </span>
      </span>
      <span class="text-sm text-gray-600">
        {{ t('settings.users.list.access-level.title') }}
      </span>
    </div>
    <QueryDataListWrapper
      :query
      class="!rounded-none !border-0"
    >
      <template #empty>
        <div class="px-4.5 py-4 text-sm text-gray-600">
          {{ t('settings.users.list.empty-message') }}
        </div>
      </template>

      <template #error>
        <DTAlert
          variant="yellow"
          class="m-5 p-6 text-sm"
        >
          <LineAlertCircle class="size-4" />
          {{ t('settings.users.list.fetch-error') }}
        </DTAlert>
      </template>

      <div class="divide-y divide-gray-200">
        <ListItemBoundary
          v-for="permission in permissions"
          :key="permission.id"
        >
          <SettingsUserListRow
            :permission="permission"
            @open="editModal?.open"
            @manage-access="permissionToManageAccessFor = permission"
          />
        </ListItemBoundary>
      </div>
    </QueryDataListWrapper>
  </div>

  <div
    v-if="isPaginationVisible"
    class="mt-6 p-4 lg:mt-8"
  >
    <Pagination
      :current-page="page"
      :total-pages="pageCount"
      @update="(newPage) => page = newPage"
    />
  </div>
</template>
